
class MenuHelper {
  constructor() {
    this.menuHambEl = document.querySelector('.menu-hamb');

    this.init();
  }

  init() {
    this.menuHambEl.addEventListener('click', e => { this._handleMenuHamb(e) });

    this._activeSubmenu();

    //open/close submenu
    document.querySelectorAll('.header-mobile .navigation__option-w-children > span').forEach(el => {
      el.addEventListener('click', e => { this._handleSubmenuOption(el) });
    });

  }

  _handleMenuHamb(e) {
    e.currentTarget.classList.toggle('menu-hamb--active');

    if(e.currentTarget.classList.contains('menu-hamb--active'))
      this._openMenu();
    else
      this._closeMenu();
  }

  _openMenu() {
    document.documentElement.classList.add('header-mobile-active');
  }

  _closeMenu() {
    document.documentElement.classList.remove('header-mobile-active');

    document.querySelectorAll('.header-mobile .navigation__option').forEach(el => {
      el.classList.remove('navigation__option--open');
    });

    const activeOptionEl = document.querySelector('.header-mobile .navigation__option .active');
    if(activeOptionEl) {
      document.querySelector('.navigation__option--active').classList.add('navigation__option--open');
      const bgColor = activeOptionEl.getAttribute('data-menu-color');
      document.querySelector('.header-mobile').setAttribute('data-bg-color', bgColor);
    } else {
      document.querySelector('.header-mobile').classList.add('header-mobile--active-menu');
      document.querySelector('.header-mobile').setAttribute('data-bg-color', '');
    }
  }

  _activeSubmenu() {
    //color menu with active menu
    const activeOptionHasChildren = document.querySelector('.header-mobile .navigation__option-w-children .active') ? true : false;

    const activeOptionEl = document.querySelector('.header-mobile .navigation__option .active');
    if(activeOptionEl) {
      const bgColor = activeOptionEl.getAttribute('data-menu-color');

      if(activeOptionHasChildren) {
        activeOptionEl.closest('.navigation__option-w-children').classList.add('navigation__option--active');
        activeOptionEl.closest('.navigation__option-w-children').classList.add('navigation__option--open');
      } else {
        activeOptionEl.closest('.navigation__option').classList.add('navigation__option--active');
        activeOptionEl.closest('.navigation__option').classList.add('navigation__option--open');
      }

      document.querySelector('.header-mobile').classList.add('header-mobile--active-menu');

      document.querySelector('.header-mobile').setAttribute('data-bg-color', bgColor);
    }
  }

  _handleSubmenuOption(el) {
    if(el.closest('.navigation__option-w-children').classList.contains('navigation__option--open'))
      this._closeSubmenu(el);
    else
      this._openSubmenu(el);
  }

  _closeSubmenu(el) {
    const activeSubmenuEl = document.querySelector('.header-mobile .navigation__option .active');
    //color of active menu
    if(activeSubmenuEl) {
      const bgColor = activeSubmenuEl.getAttribute('data-menu-color');
      document.querySelector('.header-mobile').setAttribute('data-bg-color', bgColor);
    } else {
      document.querySelector('.header-mobile').classList.add('header-mobile--active-menu');
      document.querySelector('.header-mobile').setAttribute('data-bg-color', '');
    }

    //close
    el.closest('.navigation__option-w-children').classList.remove('navigation__option--open');
    document.querySelector('.navigation').classList.remove('navigation--children-open');

    //open active menu
    if(activeSubmenuEl)
      document.querySelector('.navigation__option--active').classList.add('navigation__option--open');
  }

  _openSubmenu(el) {
    document.querySelector('.header-mobile').classList.add('header-mobile--active-menu');

    //color of open new menu
    const bgColor = el.parentElement.querySelector('.navigation__option a').getAttribute('data-menu-color');
    document.querySelector('.header-mobile').setAttribute('data-bg-color', bgColor);

    //close other submenus
    document.querySelectorAll('.header-mobile .navigation__option--open').forEach(el => {
      el.classList.remove('navigation__option--open');
    });
    document.querySelector('.navigation').classList.remove('navigation--children-open');

    //open
    el.closest('.navigation__option-w-children').classList.add('navigation__option--open');
    document.querySelector('.navigation').classList.add('navigation--children-open');
  }
};

export default MenuHelper;
