import 'html5-boilerplate/dist/js/vendor/modernizr-3.11.2.min.js';
import 'html5-boilerplate/dist/js/plugins.js';

import LogoIti from './modules/logo-iti.js';
import ItiSlideshow from './modules/iti-slideshow.js';
import MenuHelper from './modules/menu-helper.js';


function syncHeight() {
  document.documentElement.style.setProperty(
    '--window-inner-height',
    `${window.innerHeight}px`
  );
}

document.addEventListener('DOMContentLoaded', () => {
  new LogoIti(document.querySelector('.logo-svg--mobile'), true);
  new LogoIti(document.querySelector('.logo-svg--desktop'));

  new MenuHelper();

  document.querySelectorAll('[data-glide-slideshow]').forEach(el => {
    const slds = new ItiSlideshow(el);
  });

  window.addEventListener('resize', syncHeight);
});
