import Glide from '@glidejs/glide';

class ItiSlideshow {
  constructor(el) {
    this.el = el;

    this.glide = undefined;

    this.countSlides = this.el.querySelectorAll('.glide__slide').length;

    this.hasCounter = this.el.querySelector('[data-glide-counter]');

    this._init();
  }

  /** INIT **/
  _init() {
    this.glide = new Glide(this.el, {
      type: 'carousel',
      autoplay: false,
      animationDuration: 500,
    });

    //update active caption
    if(this.el.querySelectorAll('[data-glide-caption]').length > 0)
      this.el.querySelectorAll('[data-glide-caption]')[0].classList.add('active');

    this.glide.on('run', function(move) {
      //update active caption
      if(this.el.querySelectorAll('[data-glide-caption]').length > 0) {
        this.el.querySelectorAll('[data-glide-caption]').forEach(function(el) {
          el.classList.remove('active');
        });
        this.el.querySelectorAll('[data-glide-caption]')[this.glide.index].classList.add('active');
      }

      //update counter
      if(this.hasCounter)
        this.el.querySelector('[data-glide-counter] [data-glide-current]').innerHTML = (this.glide.index +1);
    }.bind(this));

    //update counter
    if(this.hasCounter) {
      this.el.querySelector('[data-glide-counter] [data-glide-current]').innerHTML = 1;
      this.el.querySelector('[data-glide-counter] [data-glide-total]').innerHTML = this.countSlides;
    }


    if(this.countSlides > 1) {
      //init/mount slide
      this.glide.mount();
    }
  }


  destroy() {
    this.glide.destroy();
  }


  goToPage(page) {
    this.glide.update({ startAt: page })
  }
};

export default ItiSlideshow;
